import { CommonAction } from "../../types/commonTypes";
import { IGqlState, GqlAction, GqlActionTypes } from "../../types/gqlTypes";

let initialState: IGqlState = {
  currentAnswer: null,
  currentList: null,
  currentListAnswers: [],
  allLists: [],
  sources: [],
};

export const gqlReducer = (
  state = initialState,
  action: GqlAction | CommonAction
): IGqlState => {
  let filtered;
  switch (action.type) {
    case GqlActionTypes.CREATE_ANSWER_SUCCESS:
      return {
        ...state,
        currentAnswer: action.payload,
      };
    case GqlActionTypes.GET_LIST_BY_ID_SUCCESS:
      return {
        ...state,
        currentList: action.payload,
        currentListAnswers: [],
      };
    case GqlActionTypes.GET_ANSWER_FOR_CURRENT_LIST_SUCCESS:
      return {
        ...state,
        currentListAnswers: [...state.currentListAnswers, action.payload],
      };
    case GqlActionTypes.CLEAR_CURRENT_LIST_STATE:
      return {
        ...state,
        currentListAnswers: [],
        currentList: null,
      };
    case GqlActionTypes.GET_ALL_LISTS_SUCCESS:
      return {
        ...state,
        allLists: action.payload,
      };
    case GqlActionTypes.CREATE_LIST_SUCCESS:
      return {
        ...state,
        allLists: [...state.allLists, action.payload],
      };
    case GqlActionTypes.UPDATE_LIST_SUCCESS:
      filtered = state.allLists.filter((item) => item.id !== action.payload.id);
      return {
        ...state,
        allLists: [...filtered, action.payload],
        currentListAnswers: [],
        currentList: action.payload,
      };
    case GqlActionTypes.DELETE_LIST_SUCCESS:
      filtered = state.allLists.filter((item) => item.id !== action.payload.id);
      return {
        ...state,
        allLists: [...filtered],
      };
    case GqlActionTypes.UPDATE_SUBSCRIPTION_SUCCESS:
      let updatedSubscribers = state.currentList && {
        ...state.currentList,
        subscribers: action.payload.subscribers,
        _version: action.payload._version,
      };
      return {
        ...state,
        currentList: updatedSubscribers,
      };
    case GqlActionTypes.UPDATE_QUESTION_MAILING:
      let currentList = state.currentList && {
        ...state.currentList,
        deactivated: action.payload.deactivated,
        _version: action.payload._version,
      };
      return {
        ...state,
        currentList: currentList,
      };
    case GqlActionTypes.DELETE_ANSWER_SUCCESS:
      let filteredAnswers =
        state.currentList &&
        state.currentList!.answers &&
        state.currentList!.answers!.filter(
          (question) => action.payload.id !== question
        );

      let listWithoutDeletedAnswer = state.currentList && {
        ...state.currentList,
        answers: filteredAnswers,
      };

      return {
        ...state,
        currentList: listWithoutDeletedAnswer,
      };
    case GqlActionTypes.FETCH_SOURCES_SUCCESS:
      return {
        ...state,
        sources: action.payload,
      };
    default:
      return state;
  }
};
