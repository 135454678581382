import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { rootReducer } from "./reducers";

// export const store = createStore(rootReducer, applyMiddleware(thunk));

const composeByEnv = () => {
  if (
    process?.env?.NODE_ENV === "development" &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION__
  ) {
    return compose(
      applyMiddleware(thunk),
      process?.env?.NODE_ENV === "development" &&
        (window as any).__REDUX_DEVTOOLS_EXTENSION__ &&
        (window as any).__REDUX_DEVTOOLS_EXTENSION__({ trace: true, traceLimit: 25 })
    );
  } else {
    return applyMiddleware(thunk);
  }
};
export const store = createStore(rootReducer, composeByEnv());
