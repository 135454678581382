import {
  Answer,
  List,
  ModelAnswerConnection,
  ModelListConnection,
  Source,
} from "../API";

// state
export interface IGqlState {
  currentAnswer: Answer | null;
  currentList: List | null;
  allLists: List[];
  currentListAnswers: Answer[];
  sources: Source[];
}
// action types
export enum GqlActionTypes {
  GET_LIST_BY_ID_SUCCESS = "GET_LIST_BY_ID_SUCCESS",
  GET_ALL_LISTS_SUCCESS = "GET_ALL_LISTS_SUCCESS",
  SYNC_LISTS_SUCCESS = "SYNC_LISTS_SUCCESS",
  GET_ANSWER_BY_ID_SUCCESS = "GET_ANSWER_BY_ID_SUCCESS",
  GET_ANSWER_FOR_CURRENT_LIST_SUCCESS = "GET_ANSWER_FOR_CURRENT_LIST_SUCCESS",
  GET_ALL_ANSWERS_SUCCESS = "GET_ALL_ANSWERS_SUCCESS",
  SYNC_ANSWERS_SUCCESS = "SYNC_ANSWERS_SUCCESS",
  CREATE_LIST_SUCCESS = "CREATE_LIST_SUCCESS",
  UPDATE_LIST_SUCCESS = "UPDATE_LIST_SUCCESS",
  DELETE_LIST_SUCCESS = "DELETE_LIST_SUCCESS",
  CREATE_ANSWER_SUCCESS = "CREATE_ANSWER_SUCCESS",
  UPDATE_ANSWER_SUCCESS = "UPDATE_ANSWER_SUCCESS",
  DELETE_ANSWER_SUCCESS = "DELETE_ANSWER_SUCCESS",
  CLEAR_CURRENT_LIST_STATE = "CLEAR_CURRENT_LIST_STATE",
  UPDATE_SUBSCRIPTION_SUCCESS = "UPDATE_SUBSCRIPTION_SUCCESS",
  UPDATE_QUESTION_MAILING = "UPDATE_QUESTION_MAILING",
  FETCH_SOURCES_SUCCESS = "FETCH_SOURCES_SUCCESS",
}

interface IGetListByIdSuccessAction {
  type: GqlActionTypes.GET_LIST_BY_ID_SUCCESS;
  payload: List;
}

interface IGetAllListsSuccessAction {
  type: GqlActionTypes.GET_ALL_LISTS_SUCCESS;
  payload: List[];
}

interface ISyncListsSuccessAction {
  type: GqlActionTypes.SYNC_LISTS_SUCCESS;
  payload: ModelListConnection;
}
interface IGetAnswerByIdSuccessAction {
  type: GqlActionTypes.GET_ANSWER_BY_ID_SUCCESS;
  payload: Answer;
}
interface IGetAnswerForCurrentListSuccessAction {
  type: GqlActionTypes.GET_ANSWER_FOR_CURRENT_LIST_SUCCESS;
  payload: Answer;
}
interface IGetAllAnswersSuccessAction {
  type: GqlActionTypes.GET_ALL_ANSWERS_SUCCESS;
  payload: ModelAnswerConnection;
}
interface ISyncAnswersSuccessAction {
  type: GqlActionTypes.SYNC_ANSWERS_SUCCESS;
  payload: ModelAnswerConnection;
}
interface ICreateListSuccessAction {
  type: GqlActionTypes.CREATE_LIST_SUCCESS;
  payload: List;
}
interface IUpdateListSuccessAction {
  type: GqlActionTypes.UPDATE_LIST_SUCCESS;
  payload: List;
}
interface IDeleteListSuccessAction {
  type: GqlActionTypes.DELETE_LIST_SUCCESS;
  payload: List;
}
interface ICreateAnswerSuccessAction {
  type: GqlActionTypes.CREATE_ANSWER_SUCCESS;
  payload: Answer;
}
interface IUpdateAnswerSuccessAction {
  type: GqlActionTypes.UPDATE_ANSWER_SUCCESS;
  payload: Answer;
}
interface IDeleteAnswerSuccessAction {
  type: GqlActionTypes.DELETE_ANSWER_SUCCESS;
  payload: Answer;
}
interface IClearCurrentListStateAction {
  type: GqlActionTypes.CLEAR_CURRENT_LIST_STATE;
}
interface IUpdateSubscriptionSuccessAction {
  type: GqlActionTypes.UPDATE_SUBSCRIPTION_SUCCESS;
  payload: { subscribers: string[]; _version: number };
}
interface IUpdateQuestionMailingAction {
  type: GqlActionTypes.UPDATE_QUESTION_MAILING;
  payload: { deactivated: string[]; _version: number };
}
interface IFetchSourcesSuccessAction {
  type: GqlActionTypes.FETCH_SOURCES_SUCCESS;
  payload: Source[];
}
export type GqlAction =
  | IGetListByIdSuccessAction
  | IGetAllListsSuccessAction
  | ISyncListsSuccessAction
  | IGetAnswerByIdSuccessAction
  | IGetAnswerForCurrentListSuccessAction
  | IGetAllAnswersSuccessAction
  | ISyncAnswersSuccessAction
  | ICreateListSuccessAction
  | IUpdateListSuccessAction
  | IDeleteListSuccessAction
  | ICreateAnswerSuccessAction
  | IUpdateAnswerSuccessAction
  | IDeleteAnswerSuccessAction
  | IClearCurrentListStateAction
  | IUpdateSubscriptionSuccessAction
  | IUpdateQuestionMailingAction
  | IFetchSourcesSuccessAction;
